<main class="main main-background relative">
  <app-menu-locale [cornerPosition]="true"></app-menu-locale>
  <section class="h-full bg-grey-100 w-3/12">
    <img src="../../assets/img/logo/logo.png" class="ml-8 mt-8"/>
    <h1 class="my-2 mx-8 overline-o1 primary-text">{{'onboarding.title' | translate }}</h1>
    <hr class="divider w-full">
    <form [formGroup]="onboardingForm" (ngSubmit)="sendOnboarding()" class="px-8 overflow-y-auto content-height overflow-x-hidden relative">
      <h3 class="link primary-text">{{'login.accessData' | translate}}</h3>
      <app-input [type]="'text'" [control]="onboardingForm.get('user.name')" [label]="('login.name' | translate) + '*'" [placeholder]="'login.name' | translate" data-test="userName"></app-input>
      <app-input [type]="'text'" [control]="onboardingForm.get('user.email')" [label]="('login.email' | translate) + '*'" [placeholder]="'login.emailPlaceholder' | translate" data-test="email"></app-input>
      <app-input [type]="'password'" [control]="onboardingForm.get('user.password')" [label]="('login.createPass' | translate) + '*'" [hint]="('login.sixCharacters' | translate) + '</br>' + ('login.passStyle' | translate) + '</br>'" data-test="password"></app-input>
      <app-input [type]="'password'" [control]="onboardingForm.get('user.repeatPassword')" [label]="('login.repeatPass' | translate) + '*'" data-test="repeatPassword"></app-input>
      <app-control-error [control]="onboardingForm.get('user')" data-test="notMatch"></app-control-error>

      <app-input [type]="'text'" [control]="onboardingForm.get('organization.name')" [label]="('onboarding.organization.name' | translate) + '*'" [placeholder]="'onboarding.organization.name' | translate" data-test="organizationName"></app-input>

      <app-input [type]="'text'" [control]="onboardingForm.get('organization.legalId')" [label]="('onboarding.organization.idCode' | translate) + '*'" [placeholder]="'onboarding.organization.idCodePlaceholder' | translate" [hint]="'onboarding.organization.codeStyle' | translate" class="w-4/5" data-test="legalId"></app-input>

      <app-input [type]="'text'" [control]="onboardingForm.get('organization.phone')" [label]="'login.phone' | translate" [placeholder]="'login.phonePlaceholder' | translate" data-test="phone"></app-input>

      <div class="flex flex-col items-start pb-10">
        <mat-checkbox formControlName="terms" class="mb-4 -ml-2" data-test="acceptTerms">
          <mat-label class="mr-1">
            <span class="label">{{'login.accept' | translate}}</span>
            <a [href]="'assets/documents/'+ lang +'/terms-and-conditions.pdf'" target="_blank" id="onboarding-terms-and-conditions" class="inline link mr-8">{{('login.termsConditions' | translate) + '*'}}</a>
          </mat-label>
        </mat-checkbox>
        <app-control-error [control]="onboardingForm.get('terms')" data-test="requiredTerms"></app-control-error>
      </div>

      <div class="fixed w-3/12 bottom-0 left-0 right-0 z-10 -ml-8 bg-grey-100">
        <hr class="divider mt-0 mb-4 mr-10">
        <div class="ml-8">
          <app-button-secondary [type]="'submit'" class="mx-8" data-test="submit">{{'buttons.continue' | translate}}</app-button-secondary>
          <a routerLink="/login" class="link m-6 mb-10 block">{{'login.haveAnAccount' | translate}}</a>
        </div>
      </div>
    </form>
  </section>
</main>


