import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { firstValueFrom } from 'rxjs';

import { MODAL_WIDTH, TERMS_AND_CONDITIONS_HASH } from '../../../../../common/models/constants';
import { COMPANY_ID_TYPE, User } from '../../../../../common/models/main';
import { ApiService } from '../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../common/shared/custom-validators';
import { MainEventService } from '../../../../../common/shared/main-event.service';
import { CacheService } from '../../core/services/cache.service';
import { EnvService } from '../../core/services/env.service';
import { ValidateEmailModalComponent } from './validate-email/validate-email-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})

export class OnboardingComponent implements OnInit {

  types = Object.keys(COMPANY_ID_TYPE);
  onboardingForm: FormGroup = this.createForm();
  user!: User;
  captchaKey: string = this.env.captchaKey;
  lang: string = this.cache.lang;

  constructor(private env: EnvService,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private cache: CacheService,
              private ar: ActivatedRoute,
              private api: ApiService,
              private router: Router,
              private dialog: MatDialog,
              private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    MainEventService.localeChanged.subscribe(() => this.lang = this.cache.lang);
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe(async queryParams => {
      if (queryParams.locale) this.cache.locale = queryParams.locale;
      // No hay usuario
      if (!this.cache.getToken()) {
        // Si hay codigo --> validamos su email
        if (queryParams.validationCode) {
          this.api.logWarn('toast.makeLogin', true);
          return this.router.navigate(['login'], { queryParams });
        }
        // No hay codigo --> formulario para que haga el alta
        return this.createForm();
      }
      // Hay usuario
      this.user = await this.cache.getUserPromise();
      // Si esta validado le sacamos del onboarding xq ya ha terminado
      if (this.user.emailValidatedAt) return await this.router.navigate(['/']);

      if (queryParams.validationCode) return this.validateEmail(queryParams.validationCode);
      else return this.dialog.open(ValidateEmailModalComponent, { data: {}, ...MODAL_WIDTH, disableClose: true });
    });
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      user: this.formBuilder.group({
        name: [null, [Validators.required]],
        email: [null, [Validators.required, CustomValidators.validateEmail]],
        password: [null, [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
        repeatPassword: [null, [Validators.required]],
      }, { validators: CustomValidators.matchPassword }),
      organization: this.formBuilder.group({
        name: [null, [Validators.required]],
        phone: [null, []],
        legalId: [null, [Validators.required]],
      }),
      terms: [null, [Validators.required, Validators.requiredTrue]],
    });
  }

  async sendOnboarding() {
    if (this.onboardingForm.invalid) {
      this.onboardingForm.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    const captchaResponse = this.env.captchaKey ? await this.reCaptchaV3Service.executeAsPromise(this.env.captchaKey, 'login', { useGlobalDomain: true }) : '';

    const data = this.onboardingForm.getRawValue();
    delete data.user.repeatPassword;

    data.user.locale = this.cache.locale;
    data.user.legalTermsHash = TERMS_AND_CONDITIONS_HASH;
    data.user.email = data.user.email.trim();
    const res = await firstValueFrom(this.api.signUp(data, captchaResponse));
    this.user = res.user;
    MainEventService.login.emit(res);
    this.dialog.open(ValidateEmailModalComponent, {
      ...MODAL_WIDTH,
      disableClose: true,
      data: {
        code: res.validationCode,
        cb: () => this.validateEmail(res.validationCode as string), // Callback para ejecutar el validar email si hubiese codigo.
      },
    });
  }

  validateEmail(validationCode: string) {
    this.api.validateEmail({ emailValidationCode: validationCode }).subscribe(async () => {
      this.user.emailValidatedAt = new Date();
      this.dialog.closeAll();
      await this.router.navigate(['/record-list']);
    }, () => this.dialog.open(ValidateEmailModalComponent, { data: {}, ...MODAL_WIDTH, disableClose: true }));
  }
}
