import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import countries from '../components/autocomplete-select/countries';
import currencies from '../components/autocomplete-select/currencies';
import incoterms from '../components/autocomplete-select/incoterms';

@Pipe({ name: 'cast' })
export class CastPipe implements PipeTransform {
  transform(value: any): any {
    return value;
  }
}

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? text.replace(regex, match => `<b>${match}</b>`) : text;
  }
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(text: string | null | undefined, length: number = 20, suffix: string = '...'): string {
    if (!text) return '';
    if (text.length > length) {
      return text.substring(0, length).trim() + suffix;
    }
    return text;
  }
}

@Pipe({ name: 'sortTranslation' })
export class SortTranslationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(array: Array<string>, prefix: string): string[] {

    const elementsArray = Array.from(array);
    const data = this.translate.instant((elementsArray).map(e => prefix + e));
    elementsArray.sort((a: any, b: any) => {

      // Esto lo hacemos para que "Others" siempre aparezca de último en el select
      if (a === 'other' || a === 'others') return +1;
      if (b === 'other' || b === 'others') return -1;

      return (data[prefix + a] < data[prefix + b]) ? -1 : 1;
    });
    return elementsArray;
  }
}

@Pipe({ name: 'newlineToBr' })
export class NewlineToBrPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/\n/g, '<br>');
    }
    return value;
  }
}

@Pipe({ name: 'translateJson' })

export class TranslateJsonPipe implements PipeTransform {
  transform(value: string, type: 'incoterms'): string {
    let translationItem;
    if (type === 'incoterms') translationItem = incoterms.find(item => item.value === value);
    return translationItem ? translationItem.text : value;
  }
}

@Pipe({ name: 'translateCustom' })
export class TranslateCustom implements PipeTransform {
  constructor(private translate: TranslateService, private currency: CurrencyPipe) {
  }

  transform(value: any, type: 'paymentMethod' | 'currencyValue' | 'countries' | 'currency'): any {
    if (!value) return '';
    // Esto es un valor + una moneda
    if (type === 'currencyValue') return this.currency.transform(value.amount, value.currency);
    if (type === 'paymentMethod') return this.translate.instant('select.paymentMethod.' + value);
    if (type === 'countries') return countries.find(e => e.value === value)?.text;
    if (type === 'currency') return currencies.find(e => e.value === value)?.text;
  }
}
