import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SegmentService } from 'ngx-segment-analytics';
import { noop } from 'rxjs';

import packageJSON from '../../../../package.json';
import { Theme, User } from '../../../common/models/main';
import { ApiService } from '../../../common/services/api.service';
import { MainEventService } from '../../../common/shared/main-event.service';
import { CacheService } from './core/services/cache.service';
import { EnvService } from './core/services/env.service';
import { EventService } from './core/services/event.service';
import { HelperService } from './core/services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLogged: boolean = this.cache.getToken() ? HelperService.getTimeToExpireToken(this.cache.getToken() as string) > 0 : false; // Si hay token y ademas no ha expirado
  timers = { warnSession: 0, closeSession: 0 };
  MainEventService = MainEventService;
  @HostBinding('class') className = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public eventService: EventService, // publico xq se usa desde la vista
    private api: ApiService,
    private router: Router,
    private cache: CacheService,
    private dialog: MatDialog,
    private segment: SegmentService,
    private helper: HelperService,
    private env: EnvService,
    private title: Title,
  ) {}

  ngOnInit(): void {

    if (window.location.hostname.includes('docguardian.usyncro.com') && !window.location.pathname.includes('doc-guardian')) this.router.navigate(['/doc-guardian' + window.location.pathname]).then(noop);

    if (this.env.name !== 'open') this.title.setTitle('Usyncro - ' + this.env.name);

    this.router.events.subscribe(async (event) => {
      if (!(event instanceof NavigationEnd)) return;
      const user = this.isLogged ? await this.cache.getUserPromise() : {} as User;
      await this.segment.page(event.urlAfterRedirects.slice(1), {}, { context: { group_id: user?.organization?.id, company_name: user?.organization?.name } });
    });

    MainEventService.login.subscribe(res => {
      // centralizo la llamada a cache service en el app.ts
      if (res) {
        this.isLogged = true;
        this.cache.setToken(res.token, !res.user.organization);
        this.cache.locale = res.user.locale;
        this.addSessionLimitTime(res.token);
        this.sendSegmentData(res.user);
      } else {
        this.clearTimers();
        this.dialog.closeAll();
        this.cache.clearStorage();
        this.isLogged = false;
      }
    });

    MainEventService.socketConnected.subscribe(() => this.checkLastVersion());
    setInterval(() => this.checkLastVersion(), 86400000); // 24h in seconds

    this.eventService.theme.subscribe(theme => this.changeThemeMode(theme));
    this.eventService.theme.next(this.cache.theme);
  }

  // Funcionalidad temporal para desarrollo
  private changeThemeMode(theme: Theme) {
    const darkClassName = 'dark-theme';
    const lightClassName = 'light-theme';
    this.className = theme;
    this.cache.theme = theme;
    if (theme === 'dark') {
      this.document.body.classList.add(darkClassName);
      this.document.body.classList.remove(lightClassName);
    } else {
      this.document.body.classList.remove(darkClassName);
      this.document.body.classList.add(lightClassName);
    }
  }

  private addSessionLimitTime(token: string) {
    const time = HelperService.getTimeToExpireToken(token);
    this.clearTimers();

    this.timers.warnSession = window.setTimeout(() => { // Me guardo el id para quitarlo si se hace logout
      this.api.logWarn('toast.warnSession');
    }, time - 60000);

    this.timers.closeSession = window.setTimeout(() => { // Me guardo el id para quitarlo si se hace logout
      this.api.logWarn('toast.closeSession');
      MainEventService.login.next();
      this.helper.redirectToLogin().then(noop);
    }, time);
  }

  private clearTimers() {
    clearTimeout(this.timers.warnSession);
    clearTimeout(this.timers.closeSession);
    this.timers = { warnSession: 0, closeSession: 0 };
  }

  private checkLastVersion() {
    this.api.getLastVersion().subscribe(serverVersion => {
      if (serverVersion && serverVersion !== packageJSON.version) {
        this.api.logWarn('toast.newVersion', true);
        setTimeout(() => window.location.reload(), 5000);
      }
    });
  }

  private sendSegmentData(user: User) {
    this.segment.identify(user.external.segment, {
      userId: user.external.segment,
      name: user.name,
      email: user.email,
      createdAt: user.createdAt,
      guest: !user.organization?.id,
      admin: user.isAdmin,
      manager: user.isManager,
      language: user.locale,
      environment: this.env.name,
    }).then(noop);
    if (user.organization) {
      const organization = user.organization;
      this.segment.group(organization.id, {
        id: organization.id,
        name: organization.name,
      }).then(noop);
    }
  }
}
